<template>
  <v-container
    id="extended-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="far fa-calendar-alt"
      icon-small
      color="primary"
      :title="$t('pageTitles.Contacts/ContactsList')"
    >
      <template #subtitle>
        <div class="text-right text-h3">
          <app-btn
            color="success"
            class="px-2 ml-1"
            elevation="0"
            min-width="0"
            small
            to="/contacts/new"
          >
            <v-icon
              small
              v-text="'fas fa-plus'"
            />
          </app-btn>
        </div>
      </template>

      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('forms.label') }}</th>
            <th class="text-right">
              {{ $t('forms.actions') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!contacts || !contacts.length">
          <tr>
            <td colspan="3">
              <i
                v-if="processing"
                class="fas fa-spin fa-spinner"
              ></i>
              <template v-else>
                {{ $t('forms.nodata') }}
              </template>
            </td>
          </tr>
        </tbody>

        <draggable
          v-else
          v-model="contacts"
          tag="tbody"
          handle=".fa-arrows-alt"
          @change="sortHandler"
        >
          <tr
            v-for="contact in contacts"
            :key="`contact-list-${contact.id}`"
          >
            <td>
              <i class="fas fa-arrows-alt"></i>
            </td>
            <td>{{ contact.label }}</td>
            <td class="text-right table-actions">
              <v-btn
                color="success"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                :to="`/contacts/${contact.id}`"
              >
                <v-icon
                  small
                  v-text="'far fa-edit'"
                />
              </v-btn>
              <app-btn
                color="error"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                @click="initiateContactDelete(contact)"
              >
                <v-icon
                  small
                  v-text="'fas fa-trash'"
                />
              </app-btn>
            </td>
          </tr>
        </draggable>
      </v-simple-table>
    </material-card>
    <v-dialog
      v-model="showDeletionDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteContact"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../../mixins/serverError';
export default {
  name: 'ContactList',

  mixins: [serverError],

  data: () => ({
    contacts: [],
    showDeletionDialog: false,
    contactToDelete: null,
  }),

  computed: {
    ...mapGetters({
      allContacts: 'contact/getContacts',
      processing: 'global/getProcessing',
    }),
  },

  created() {
    this.$store.commit('contact/setContact', null);
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.contacts = [];
      this.$store.dispatch('contact/fetchContacts')
        .then(() => {
          this.contacts = [...this.allContacts];
        });
    },
    sortHandler() {
      const order = this.contacts.map(contact => contact.id);
      const payload = { order };
      this.$store.dispatch('contact/reorderContacts', payload)
        .then(response => {
          this.contacts = response.data.data;
        })
        .catch(() => this.$toasted.error(this.$t('error_happened')));
    },
    initiateContactDelete(contact) {
      this.contactToDelete = contact.id;
      this.showDeletionDialog = true;
    },
    closeDeletionDialog() {
      this.contactToDelete = null;
      this.showDeletionDialog = false;
    },
    deleteContact() {
      this.$store.dispatch('contact/deleteContact', this.contactToDelete)
        .then(() => {
          this.$toasted.success(this.$t('item_deleted'));
          this.refreshData();
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.closeDeletionDialog());
    },
  },
}
</script>

<style lang="scss">
.fa-arrows-alt {
  cursor: grab;
}
</style>
